/*@import "custom";*/
@import './variables';
@import "~bootstrap/scss/bootstrap";


@keyframes opacityFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:root {
  --progress-correct: 0;
  --progress-fail: 0;
}

html, body {
  background-color: $bg-dark;
  font-size: 20px;
  overflow-x: hidden;
}

body {
  -webkit-overflow-scrolling: touch;
}

// make title fit in iphone5/6
h1 {
  font-size: 1.2rem;
}

@media (min-width: 670px) {
  h1 {
    font-size: 1.8rem;
  }
}

a {
  text-decoration: underline;
  color: inherit;
}

.footnote {
  font-size: 0.8rem;
}

.App {
  background-color: $bg-light;
  display: flex;
  flex-direction: column;

  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  min-height: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  background-image: linear-gradient(to top, $bg-dark, $bg-dark $footer-height, transparent $footer-height);
}

header {

  background-color: $bg-dark;
  color: white;
  //padding: 20px;
  height: $header-height;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .container.title {
    flex: 1 1 auto;
  }

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //background-image: url('img/RES_N+Z RGB DIAP.svg');
    //background-position: calc(100% - 10px);
    //background-repeat: no-repeat;
    //background-size: auto $header-height - 10px;

    h1 {
      margin: 0;
      padding: 0;
      //margin-left: 10px;
      color: $blue-light;
    }

    .logo {
      width: 30vw;
      max-width: 300px;
    }

  }

  .answer-feedback {
    display: flex;
    height: $header-height - $progress-height;

    justify-content: flex-start;
    width: 100%;
    align-items: center;
    animation-name: opacityFadeIn;
    animation-duration: 0.2s;

    h1 {
      color: white;
      font-weight: 300;
    }

    img {
      height: $header-height / 3*2;
      width: $header-height / 3*2;
      margin-right: 10px;

    }
  }

  .progress-bar {

    height: $progress-height;
    background-color: $progress-bg;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    .correct {
      transition: width 0.2s;
      width: calc(var(--progress-correct) * 1%);
      background-color: $progress-green;
      height: inherit;
    }

    .fail {
      transition: width 0.2s;
      width: calc(var(--progress-fail) * 1%);
      background-color: $progress-grey;
      height: inherit;
    }

  }
}

.Page {

  min-height: calc(100vh - #{$header-height});
  min-height: calc((var(--vh, 1vh) * 100) - #{$header-height});

  display: flex;
  flex-direction: column;
  //flex: 1 1 auto;
  //height: 100vh;


  .body {
    background-color: $bg-light;
    padding: 10px;
    //height: 100%;
    background-image: url('./img/logo_onder.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 200px auto;
    @media (min-width: 670px) {
      background-size: 300px auto;
    }
    padding-bottom: 60px; // for bottom page logo image
    min-height: calc(100vh - #{$header-height} - #{$header-height});
    min-height: calc((var(--vh, 1vh) * 100) - #{$header-height} - #{$header-height});

  }

  .footer {

    background-color: $bg-dark;
    height: $footer-height;

    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      width: 100%;
      max-width: 250px;
      color: white;
      background-color: black;
      border: 1px solid white;
      box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
      border-radius: 20px;
      height: 40px;
      margin: 10px;
      //font-size: 20px;
      line-height: 1rem;
      font-weight: bold;

      &:active {
        background-color: black;
      }

      &.btn-start {
        background-color: $green;
        color: black;
      }
    }
  }
}